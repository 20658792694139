/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveTeam } from "@dataResolvers";
import { About as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query AboutQuery {
    craft {
      entry(section: "about") {
        title
        url
        slug
        ... on Craft_about_about_Entry {
          #meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #hero
          heroLabel
          heroTitle
          heroImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #intro
          aboutWhatHow {
            ... on Craft_aboutWhatHow_aboutWhatHowBlock_BlockType {
              whatHowHeader
              whatHowDescription
              whatHowIcon {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              whatHowImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          aboutPullQuote
          #services
          aboutservicesheader
          servicesDescription
          commonImageCopyBlock {
            ... on Craft_commonImageCopyBlock_imageCopyBlock_BlockType {
              imageCopyBlockTitle
              imageCopyBlockDescription
              imageCopyBlockImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              imageCopyBlockCTA {
                text
                url
              }
              imageCopyBlockPDF {
                url
              }
            }
          }
          #team
          aboutTeamHeader
          aboutTeamDescription
        }
      }
      entries(section: "teamMembers") {
        ... on Craft_teamMembers_teamMembers_Entry {
          teamMember {
            ... on Craft_teamMember_teamMember_BlockType {
              enabled
              uid
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              fullName
              position
              email
              contactInfo {
                linkedin
              }
              bio
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `query AboutQuery {
  craft {
    entry(section: "about") {
      title
      url
      slug
      ... on Craft_about_about_Entry {
        #meta
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #hero
        heroLabel
        heroTitle
        heroImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #intro
        aboutWhatHow {
          ... on Craft_aboutWhatHow_aboutWhatHowBlock_BlockType {
            whatHowHeader
            whatHowDescription
            whatHowIcon {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            whatHowImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
        aboutPullQuote
        #services
        aboutservicesheader
        servicesDescription
        commonImageCopyBlock {
          ... on Craft_commonImageCopyBlock_imageCopyBlock_BlockType {
            imageCopyBlockTitle
            imageCopyBlockDescription
            imageCopyBlockImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            imageCopyBlockCTA {
              text
              url
            }
            imageCopyBlockPDF {
              url
            }
          }
        }
        #team
        aboutTeamHeader
        aboutTeamDescription
      }
    }
    entries(section: "teamMembers") {
      ... on Craft_teamMembers_teamMembers_Entry {
        teamMember {
          ... on Craft_teamMember_teamMember_BlockType {
            enabled
            uid
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            fullName
            position
            email
            contactInfo {
              linkedin
            }
            bio
          }
        }
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // meta
    metaTitle,
    metaDescription,
    metaImage,
    // hero
    heroLabel,
    heroTitle,
    heroImage,
    // intro
    aboutWhatHow,
    // services
    aboutservicesheader,
    servicesDescription,
    commonImageCopyBlock,
    // team
    aboutTeamHeader,
    aboutTeamDescription,
  } = craft.entry;

  const teamMembers = craft.entries;

  const resolveIntro = blocks =>
    blocks.map(b => {
      return {
        heading: b.whatHowHeader,
        copy: b.whatHowDescription,
        icon: resolveImage(b.whatHowIcon),
        image: resolveImage(b.whatHowImage),
      };
    });

  const resolveBlocks = blocks =>
    blocks.map(b => {
      return {
        heading: b.imageCopyBlockTitle,
        copy: b.imageCopyBlockDescription,
        image: resolveImage(b.imageCopyBlockImage),
        link: {
          text: b.imageCopyBlockCTA.text,
          url: b.imageCopyBlockCTA.url,
        },
        pdf: b.imageCopyBlockPDF[0]?.url,
      };
    });

  return {
    title,
    hero: {
      label: heroLabel,
      heading: heroTitle,
      image: resolveImage(heroImage),
    },
    intro: {
      blocks: resolveIntro(aboutWhatHow),
    },
    services: {
      heading: aboutservicesheader,
      copy: servicesDescription,
      blocks: resolveBlocks(commonImageCopyBlock),
    },
    team: {
      heading: aboutTeamHeader,
      copy: aboutTeamDescription,
      cards: resolveTeam(teamMembers),
    },
    meta: {
      title,
      url,
      slug,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage || heroImage),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
